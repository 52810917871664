import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import loadingIcon from '../assets/images/loading-dark.svg'
import {
  getCurrentUser,
  updateUserSetting,
  gnjAmplifyadminDisableUser,
} from '../actions/user'
import {
  updateStripeCustomerInfo,
  userBackUpJournals,
} from '../actions/membership'
import { Auth } from 'aws-amplify'
import LoadingCard from '../components/LoadingCard'
import Footer from '../components/Footer'
import { Link } from 'react-router-dom'

class Setting extends Component {
  constructor(props) {
    super(props)
    this.state = {
      darkMode: false,
      username: '',
      displayName: '',
      email: '',
      verificationCode: '',
      emailVerified: false,
      isLoading: false,
      successMsg: '',
      errorMsg: '',
      currentPassword: '',
      newPassword: '',
      passwordSuccessMsg: '',
      passwordErrorMsg: '',
      isLoadingPassword: false,
      showDeleteMyAccount: false,
      backupProcessing: false,
      backupTimeout: false,
      userEmailNotification: 'off',
    }
  }

  async componentDidMount() {
    if (localStorage.getItem('darkmode') === 'true') {
      this.setState({ darkMode: true })
    }
    try {
      await this.props.getCurrentUser()
      const displayName = this.props.user.currentUser.attributes
        .preferred_username
      this.setState({
        username: this.props.user.currentUser.username,
        displayName: displayName || this.props.user.currentUser.username,
        email: this.props.user.currentUser.attributes.email,
        userEmailNotification: this.props.user.currentUser.attributes[
          'custom:emailNotification'
        ],
      })
    } catch (err) {
      console.log(err)
    }
  }

  _onChange = async (e) => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  _updateUserSetting = async (e) => {
    e.preventDefault()
    const { displayName, email, verificationCode } = this.state
    const stripeCustomerId = this.props.user.currentUser.attributes[
      'custom:stripeCustomerId'
    ]
    if (
      (displayName ===
        this.props.user.currentUser.attributes.preferred_username ||
        displayName === this.props.user.currentUser) &&
      email === this.props.user.currentUser.attributes.email &&
      !verificationCode
    )
      return

    this.setState({
      isLoading: true,
      emailVerified: false,
      successMsg: '',
    })
    if (verificationCode) {
      try {
        const data = await Auth.verifyCurrentUserAttributeSubmit(
          'email',
          verificationCode
        )

        if (data === 'SUCCESS') {
          // if paid member, update email on Stripe as well
          if (stripeCustomerId) {
            await this.props.updateStripeCustomerInfo({
              stripeCustomerId: stripeCustomerId,
              email: email,
            })
          }
          this.setState({
            emailVerified: true,
            successMsg: 'Email verified!',
            errorMsg: '',
            verificationCode: '',
            isLoading: false,
          })
        }
      } catch (err) {
        this.setState({
          isLoading: false,
          errorMsg: err.message,
          successMsg: '',
        })
      }
    } else {
      try {
        await this.props.updateUserSetting({
          preferred_username: displayName,
          email: email,
        })
        this.setState({
          isLoading: false,
          errorMsg: '',
          successMsg: 'Updated successfully!',
        })
      } catch (err) {
        this.setState({
          isLoading: false,
          successMsg: '',
          errorMsg: 'Update failed. Please try again.',
        })
      }
    }
  }

  _resendVerificationCode = async () => {
    this.setState({ isLoading: true })
    Auth.verifyCurrentUserAttribute('email')
      .then((data) => {
        this.setState({
          errorMsg: '',
          successMsg: 'Verification code sent!',
          isLoading: false,
        })
      })
      .catch((err) => {
        this.setState({
          errorMsg: err.message,
          successMsg: '',
          isLoading: false,
        })
      })
  }

  _changePassword = async (e) => {
    e.preventDefault()
    const { currentPassword, newPassword } = this.state
    this.setState({ isLoadingPassword: true })

    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, currentPassword, newPassword)
      })
      .then((data) => {
        this.setState({
          passwordSuccessMsg: 'Password updated successfully',
          passwordErrorMsg: '',
          isLoadingPassword: false,
        })
      })
      .catch((err) => {
        this.setState({
          passwordErrorMsg: err.message,
          passwordSuccessMsg: '',
          isLoadingPassword: false,
        })
      })
  }

  _darkTheme = () => {
    let d = document.getElementsByTagName('HTML')[0]

    if (d.classList.contains('dark-mode')) {
      d.classList.remove('dark-mode')
      localStorage.setItem('darkmode', 'false')
      this.setState({
        darkMode: false,
      })
      window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage('day-mode')
    } else {
      d.classList.add('dark-mode')
      localStorage.setItem('darkmode', 'true')
      this.setState({
        darkMode: true,
      })
      window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage('dark-mode')
    }
  }

  _renderThemeSetting = () => (
    <div className="switch-wrap">
      <p>Dark mode</p>
      <label className="switch">
        <input
          type="checkbox"
          onChange={this._darkTheme}
          checked={this.state.darkMode}
        />
        <span className="slider round"></span>
      </label>
    </div>
  )

  // TODO: move this to notification action. Just to organize it better
  _setEmailNotificationSetting = async () => {
    const { userEmailNotification } = this.state
    const ask = window.confirm(
      userEmailNotification === 'off'
        ? 'Turn on email notification?'
        : 'Turn off email notification?'
    )
    if (ask) {
      let user = await Auth.currentAuthenticatedUser()
      if (userEmailNotification === 'off') {
        this.setState({ userEmailNotification: 'on' })
        await Auth.updateUserAttributes(user, {
          'custom:emailNotification': 'on',
        })
      } else {
        this.setState({ userEmailNotification: 'off' })
        await Auth.updateUserAttributes(user, {
          'custom:emailNotification': 'off',
        })
      }
    }
  }

  _renderEmailNotificationInfo = () => {
    const { userEmailNotification } = this.state
    const { currentUser } = this.props.user
    return (
      <form onSubmit={this._updateUserEmailNotificationSetting}>
        <div className="switch-wrap">
          <p>Email notification</p>
          <label className="switch">
            <input
              type="checkbox"
              onChange={this._setEmailNotificationSetting}
              checked={userEmailNotification === 'on'}
              disabled={!currentUser}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </form>
    )
  }

  _renderAccountInfo = () => {
    const {
      displayName,
      email,
      verificationCode,
      emailVerified,
      isLoading,
      errorMsg,
      successMsg,
      username,
    } = this.state
    const { currentUser } = this.props.user

    if (!currentUser) return <LoadingCard />
    return (
      <React.Fragment>
        <p>
          <code>
            Your username is <b>{username}</b>.
          </code>
        </p>
        <form onSubmit={this._updateUserSetting}>
          <div className="form-field">
            <label>
              <code>
                <small>Display name as</small>
              </code>
            </label>
            <input
              type="text"
              name="displayName"
              value={displayName}
              placeholder="Display name as"
              onChange={this._onChange}
              autoCorrect="off"
              autoCapitalize="none"
              required
            />
          </div>
          <div className="form-field">
            <label>
              <code>
                <small>Update your email address</small>
              </code>
            </label>
            <input
              type="email"
              name="email"
              value={email}
              placeholder="Email"
              onChange={this._onChange}
              autoCorrect="off"
              autoCapitalize="none"
              required
            />
          </div>
          {!currentUser.attributes.email_verified && !emailVerified && (
            <div className="form-field">
              <label>
                <code>
                  <small className="error-msg">
                    Verify your new email address(Check your email)
                  </small>
                </code>
              </label>
              <input
                type="text"
                name="verificationCode"
                value={verificationCode}
                placeholder="Verification code"
                onChange={this._onChange}
                autoCorrect="off"
                autoCapitalize="none"
              />
              <br />
              <small
                className="link-text"
                onClick={this._resendVerificationCode}
              >
                Re-send verification code
              </small>
            </div>
          )}
          <p className={successMsg ? 'success-msg' : 'error-msg'}>
            {successMsg || errorMsg}
          </p>
          <button
            type="submit"
            className="btn-secondary"
            disabled={isLoading}
            id="setting_update_account_info_button"
          >
            {isLoading ? (
              <img
                alt="Loading..."
                className="loading-icon"
                src={loadingIcon}
              />
            ) : (
              'Update account info'
            )}
          </button>
        </form>
      </React.Fragment>
    )
  }

  _renderChangePassword = () => {
    const {
      currentPassword,
      newPassword,
      passwordSuccessMsg,
      passwordErrorMsg,
      isLoadingPassword,
    } = this.state
    const { currentUser } = this.props.user

    if (!currentUser) return null
    return (
      <form onSubmit={this._changePassword}>
        <div className="form-field">
          <label>
            <code>
              <small>Current password</small>
            </code>
          </label>
          <input
            type="password"
            name="currentPassword"
            value={currentPassword}
            placeholder="Current password"
            onChange={this._onChange}
            autoCorrect="off"
            autoCapitalize="none"
            required
          />
        </div>
        <div className="form-field">
          <label>
            <code>
              <small>New password</small>
            </code>
          </label>
          <input
            type="password"
            name="newPassword"
            value={newPassword}
            placeholder="New password"
            onChange={this._onChange}
            autoCorrect="off"
            autoCapitalize="none"
            required
          />
        </div>
        <p className={passwordSuccessMsg ? 'success-msg' : 'error-msg'}>
          {passwordSuccessMsg || passwordErrorMsg}
        </p>
        <button
          type="submit"
          className="btn-secondary"
          disabled={isLoadingPassword}
          id="setting_change_password_button"
        >
          {isLoadingPassword ? (
            <img alt="Loading..." className="loading-icon" src={loadingIcon} />
          ) : (
            'Change password'
          )}
        </button>
      </form>
    )
  }

  _renderBackupJournals = () => {
    const { backupTimeout, backupProcessing } = this.state
    const { currentUser } = this.props.user

    if (!currentUser) return <LoadingCard />

    const membership = currentUser.attributes['custom:membership']
    if (!membership || membership === 'expired') {
      return (
        <div>
          <p>
            <small>
              Backup my journals is a paid feature at the moment.{' '}
              <Link to="/my-account/billing">Becoming a member</Link> will help
              us to build the community a better place and eventually offer more
              features for free to more people!
            </small>
          </p>
        </div>
      )
    } else {
      if (backupTimeout) {
        return (
          <div>
            <button
              className="btn-secondary"
              disabled
              id="setting_backup_journals_button_disabled"
            >
              Downloaded successfully
            </button>
          </div>
        )
      } else {
        return (
          <div>
            <p>
              <small>Backup is compatible on laptops or desktops only.</small>
            </p>
            <button
              className="btn-secondary"
              onClick={this._backupMyJournals}
              id="setting_backup_journals_button"
              disabled={backupProcessing}
            >
              {backupProcessing ? 'Downloading...' : 'Back up my journals'}
            </button>
          </div>
        )
      }
    }
  }

  _backupMyJournals = async () => {
    const { currentUser } = this.props.user
    let askToConfirm = window.confirm('Backup all your journals?')
    if (askToConfirm) {
      this.setState({ backupProcessing: true })
      const requestBackup = await this.props.userBackUpJournals(
        currentUser.username
      )

      if (requestBackup.downloadUrl) {
        window.open(requestBackup.downloadUrl)

        setTimeout(async () => {
          try {
            await this.props.userBackUpJournals(currentUser.username, requestBackup.fileName);
          } catch (error) {
            console.error("Error during backup:", error);
          } finally {
            this.setState({ backupTimeout: true, backupProcessing: false });
          }
        }, 5000);
      } else {
        this.setState({ backupTimeout: true, backupProcessing: false })
      }
    }
  }

  _deleteMyAccount = async () => {
    this.setState({ showDeleteMyAccount: !this.state.showDeleteMyAccount })
  }

  _deleteMyAccountRequest = async () => {
    const { currentUser } = this.props.user

    if (
      !currentUser.attributes['custom:membership'] ||
      currentUser.attributes['custom:membership'] === 'expired' ||
      currentUser.attributes['custom:membership'] === 'canceled'
    ) {
      let askToConfirm = prompt(
        'Please enter "I want to delete all my data" to continue...'
      )

      if (askToConfirm === 'I want to delete all my data') {
        console.log('Removing username: ' + currentUser.username)
        await this.props.gnjAmplifyadminDisableUser(currentUser.username)
      }
    } else {
      alert(
        'You need to cancel your memberhip first before deleting your account.'
      )
      this.props.history.push('/my-account/billing')
    }
  }

  render() {
    const { showDeleteMyAccount } = this.state

    const deleteMyAccountUI = showDeleteMyAccount ? (
      <div className="error-msg">
        <p>
          Are you sure? Deleting your account will delete all your data
          permanently and there's no way to bring them back.
        </p>
        <code
          onClick={this._deleteMyAccountRequest}
          className="trackit"
          id="setting_delete_account_continue"
        >
          <small className="link-text">Want to continue?</small>
        </code>
      </div>
    ) : (
      ''
    )

    return (
      <div className="setting Container Container--md Container--side-padding">
        <h1>Setting</h1>
        <div>
          {this._renderThemeSetting()}
          {this._renderEmailNotificationInfo()}
        </div>
        <div>
          <h3>Account information</h3>
          {this._renderAccountInfo()}
        </div>
        <div>
          <h3>Change password</h3>
          {this._renderChangePassword()}
        </div>
        <div>
          <h3>Backup my journals</h3>
          {this._renderBackupJournals()}
        </div>
        <div>
          <code
            onClick={this._deleteMyAccount}
            className="trackit"
            id="setting_delete_account_button"
          >
            <small className="link-text">
              {showDeleteMyAccount ? 'Cancel' : 'Delete my account?'}
            </small>
          </code>
          {deleteMyAccountUI}
        </div>
        <div>
          <Footer />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return state
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCurrentUser,
      updateUserSetting,
      updateStripeCustomerInfo,
      gnjAmplifyadminDisableUser,
      userBackUpJournals,
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Setting)
