import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import rootReducer from './reducers/rootReducer'
import './assets/App.css'

import App from './App'
import * as serviceWorker from './serviceWorker'

import { StripeProvider, Elements } from 'react-stripe-elements'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)))

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

ReactDOM.render(
  <Provider store={store}>
    <StripeProvider apiKey={stripePublicKey}>
      <Elements>
        <App />
      </Elements>
    </StripeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
