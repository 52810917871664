/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const isUserFollowingTheAuthor = /* GraphQL */ `
  query IsUserFollowingTheAuthor(
    $following: String!
    $user: String!
    $limit: Int
    $nextToken: String
    $sortDirection: DirectionEnum
  ) {
    isUserFollowingTheAuthor(
      following: $following
      user: $user
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        user
        following
        followingProfile
        followerProfile
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const gnjAmplifyStripeGetCustomerInfo = /* GraphQL */ `
  query GnjAmplifyStripeGetCustomerInfo($stripeCustomerId: String!) {
    gnjAmplifyStripeGetCustomerInfo(stripeCustomerId: $stripeCustomerId)
  }
`;
export const gnjAmplifyuserBackUpJournals = /* GraphQL */ `
  query GnjAmplifyuserBackUpJournals(
    $username: String!
    $deleteBackup: String
  ) {
    gnjAmplifyuserBackUpJournals(
      username: $username
      deleteBackup: $deleteBackup
    )
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      uuid
      displayName
      profileImage
      membership
      totalJournal
      followingCount
      followerCount
      bookOrder
      createdAt
      updatedAt
      lovedJournals {
        nextToken
        __typename
      }
      journals {
        nextToken
        __typename
      }
      books {
        nextToken
        __typename
      }
      publicJournals {
        nextToken
        __typename
      }
      lovedComments {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      chatRoomUser {
        nextToken
        __typename
      }
      chatMassages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFollowing = /* GraphQL */ `
  query GetFollowing($id: ID!) {
    getFollowing(id: $id) {
      id
      user
      following
      followingProfile
      followerProfile
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFollowings = /* GraphQL */ `
  query ListFollowings(
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        following
        followingProfile
        followerProfile
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userFollowingAsKey = /* GraphQL */ `
  query UserFollowingAsKey(
    $user: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userFollowingAsKey(
      user: $user
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        following
        followingProfile
        followerProfile
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userFollowerAsKey = /* GraphQL */ `
  query UserFollowerAsKey(
    $following: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userFollowerAsKey(
      following: $following
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        following
        followingProfile
        followerProfile
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const isUserFollowing = /* GraphQL */ `
  query IsUserFollowing(
    $user: String
    $following: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    isUserFollowing(
      user: $user
      following: $following
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user
        following
        followingProfile
        followerProfile
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserLovedJournal = /* GraphQL */ `
  query GetUserLovedJournal($id: ID!) {
    getUserLovedJournal(id: $id) {
      id
      userLovedJournalUserId
      userLovedJournalJournalId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listUserLovedJournals = /* GraphQL */ `
  query ListUserLovedJournals(
    $filter: ModelUserLovedJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserLovedJournals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userLovedJournalUserId
        userLovedJournalJournalId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const isUserLovedJournalAsKey = /* GraphQL */ `
  query IsUserLovedJournalAsKey(
    $userLovedJournalUserId: String
    $userLovedJournalJournalId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserLovedJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    isUserLovedJournalAsKey(
      userLovedJournalUserId: $userLovedJournalUserId
      userLovedJournalJournalId: $userLovedJournalJournalId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userLovedJournalUserId
        userLovedJournalJournalId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getJournal = /* GraphQL */ `
  query GetJournal($id: ID!) {
    getJournal(id: $id) {
      id
      privacy
      draft
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      journalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      owner
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listJournals = /* GraphQL */ `
  query ListJournals(
    $filter: ModelJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJournals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        privacy
        draft
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        journalBookId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const journalMarkedAsKey = /* GraphQL */ `
  query JournalMarkedAsKey(
    $markedAs: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    journalMarkedAsKey(
      markedAs: $markedAs
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        privacy
        draft
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        journalBookId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const journalAuthorIdMarkedAsKey = /* GraphQL */ `
  query JournalAuthorIdMarkedAsKey(
    $journalAuthorId: String
    $markedAs: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    journalAuthorIdMarkedAsKey(
      journalAuthorId: $journalAuthorId
      markedAs: $markedAs
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        privacy
        draft
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        journalBookId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const journalAuthorIdPrivacyAsKey = /* GraphQL */ `
  query JournalAuthorIdPrivacyAsKey(
    $journalAuthorId: String
    $privacy: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    journalAuthorIdPrivacyAsKey(
      journalAuthorId: $journalAuthorId
      privacy: $privacy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        privacy
        draft
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        journalBookId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const privacyCreatedAtAsKey = /* GraphQL */ `
  query PrivacyCreatedAtAsKey(
    $privacy: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    privacyCreatedAtAsKey(
      privacy: $privacy
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        privacy
        draft
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        journalBookId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBook = /* GraphQL */ `
  query GetBook($id: ID!) {
    getBook(id: $id) {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      journals {
        nextToken
        __typename
      }
      publicJournals {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listBooks = /* GraphQL */ `
  query ListBooks(
    $filter: ModelBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const isUserHasABookAsKey = /* GraphQL */ `
  query IsUserHasABookAsKey(
    $bookUserId: String
    $book: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    isUserHasABookAsKey(
      bookUserId: $bookUserId
      book: $book
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPublicJournal = /* GraphQL */ `
  query GetPublicJournal($id: ID!) {
    getPublicJournal(id: $id) {
      id
      privacy
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      publicJournalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      lovedUsers {
        nextToken
        __typename
      }
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        createdAt
        updatedAt
        __typename
      }
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listPublicJournals = /* GraphQL */ `
  query ListPublicJournals(
    $filter: ModelPublicJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicJournals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const publicJournalMarkedAsKey = /* GraphQL */ `
  query PublicJournalMarkedAsKey(
    $markedAs: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublicJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicJournalMarkedAsKey(
      markedAs: $markedAs
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const markedAsAuthorAsKey = /* GraphQL */ `
  query MarkedAsAuthorAsKey(
    $markedAs: String
    $journalAuthorId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublicJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    markedAsAuthorAsKey(
      markedAs: $markedAs
      journalAuthorId: $journalAuthorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const PrivacyDateAsKey = /* GraphQL */ `
  query PrivacyDateAsKey(
    $privacy: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublicJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PrivacyDateAsKey(
      privacy: $privacy
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const journalAuthorLatestAsKey = /* GraphQL */ `
  query JournalAuthorLatestAsKey(
    $journalAuthorId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPublicJournalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    journalAuthorLatestAsKey(
      journalAuthorId: $journalAuthorId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChallenge = /* GraphQL */ `
  query GetChallenge($id: ID!) {
    getChallenge(id: $id) {
      id
      yearMonth
      user
      userDisplayName
      userProfileImage
      journalsPerMonth
      journalWordCountPerMonth
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listChallenges = /* GraphQL */ `
  query ListChallenges(
    $filter: ModelChallengeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChallenges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        yearMonth
        user
        userDisplayName
        userProfileImage
        journalsPerMonth
        journalWordCountPerMonth
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const yearMonthUserAsKey = /* GraphQL */ `
  query YearMonthUserAsKey(
    $yearMonth: String
    $user: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChallengeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    yearMonthUserAsKey(
      yearMonth: $yearMonth
      user: $user
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearMonth
        user
        userDisplayName
        userProfileImage
        journalsPerMonth
        journalWordCountPerMonth
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const yearMonthJournalsPerMonthAsKey = /* GraphQL */ `
  query YearMonthJournalsPerMonthAsKey(
    $yearMonth: String
    $journalsPerMonth: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChallengeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    yearMonthJournalsPerMonthAsKey(
      yearMonth: $yearMonth
      journalsPerMonth: $journalsPerMonth
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        yearMonth
        user
        userDisplayName
        userProfileImage
        journalsPerMonth
        journalWordCountPerMonth
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserLovedComment = /* GraphQL */ `
  query GetUserLovedComment($id: ID!) {
    getUserLovedComment(id: $id) {
      id
      userLovedCommentUserId
      userLovedCommentCommentId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      comment {
        id
        content
        commentLovedCount
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listUserLovedComments = /* GraphQL */ `
  query ListUserLovedComments(
    $filter: ModelUserLovedCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserLovedComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userLovedCommentUserId
        userLovedCommentCommentId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const isUserLovedCommentAsKey = /* GraphQL */ `
  query IsUserLovedCommentAsKey(
    $userLovedCommentUserId: String
    $userLovedCommentCommentId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserLovedCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    isUserLovedCommentAsKey(
      userLovedCommentUserId: $userLovedCommentUserId
      userLovedCommentCommentId: $userLovedCommentCommentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userLovedCommentUserId
        userLovedCommentCommentId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      content
      commentLovedCount
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        __typename
      }
      lovedUsers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        commentLovedCount
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      content
      category
      link
      read
      actionUser {
        userID
        displayName
        photoURL
        __typename
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        category
        link
        read
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatRoomUser = /* GraphQL */ `
  query GetChatRoomUser($id: ID!) {
    getChatRoomUser(id: $id) {
      id
      chatRoomUserUserId
      chatRoomUserChatRoomId
      members
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      chatRoom {
        id
        createdBy
        receiverHasRead
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listChatRoomUsers = /* GraphQL */ `
  query ListChatRoomUsers(
    $filter: ModelChatRoomUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRoomUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chatRoomUserUserId
        chatRoomUserChatRoomId
        members
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const chatRoomUserChatRoomIdAsKey = /* GraphQL */ `
  query ChatRoomUserChatRoomIdAsKey(
    $chatRoomUserChatRoomId: String
    $chatRoomUserUserId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatRoomUserChatRoomIdAsKey(
      chatRoomUserChatRoomId: $chatRoomUserChatRoomId
      chatRoomUserUserId: $chatRoomUserUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomUserUserId
        chatRoomUserChatRoomId
        members
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const doesChatRoomExist = /* GraphQL */ `
  query DoesChatRoomExist(
    $chatRoomUserUserId: String
    $members: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatRoomUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doesChatRoomExist(
      chatRoomUserUserId: $chatRoomUserUserId
      members: $members
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatRoomUserUserId
        chatRoomUserChatRoomId
        members
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      createdBy
      receiverHasRead
      createdAt
      updatedAt
      chatRoomUsers {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listChatRooms = /* GraphQL */ `
  query ListChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdBy
        receiverHasRead
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      content
      messageChatRoomId
      messageUserId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      chatRoom {
        id
        createdBy
        receiverHasRead
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        messageChatRoomId
        messageUserId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
