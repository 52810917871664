import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import moment from "moment";

import { isLegacyUser } from "../helper/isLegacyUser";
import LoadingCard from "../components/LoadingCard";
import CouponForm from "../components/CouponForm";
import CreditCardForm from "../components/CreditCardForm";

import loadingIcon from "../assets/images/loading.svg";

function discountedPrice(price, discount) {
  let newPrice = price - price * (discount / 100);
  return Math.floor(newPrice * 100) / 100;
}

class BillingSubscriptionInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSelectedPlan: "annual"
    };
  }
  
  _reactivateMembership = async () => {
    const ask = window.confirm("Reactivate my membership?");
    if (ask) {
      this.props.reactivateMembership();
    }
  };

  getMembershipBadge = membership => {
    let membershipBadge;
    switch (membership) {
      case "annual":
        membershipBadge = "Active";
        break;
      case "monthly":
        membershipBadge = "Active";
        break;
      case "canceled":
        membershipBadge = "Canceled";
        break;
      default:
        membershipBadge = "";
    }
    return membershipBadge;
  };

  _onPlanChange = (e) => {
    this.setState({ userSelectedPlan: e.target.value })
    this.props.selectedMembershipPlan(e.target.value);
  }

  _renderFreeMember = () => {
    const {
      error,
      isLoading,
      creditCardSubmit,
      applyCouponCode,
      couponMsg,
      couponDiscount,
      isCouponLoading
    } = this.props;
    const { userSelectedPlan } = this.state

    // Show new plans for the new users who signed up after Jan 2nd, 2025
    const { user } = this.props;
    const legacySubscriptionPlan = { annual: 39.99, monthly: 4.99 };
    const newSubscriptionPlan = { annual: 49.99, monthly: 5.99 };
    const isCurrentUserLegacyUser = user.userData && isLegacyUser(user.userData.createdAt);
    const subscriptionPlan = isCurrentUserLegacyUser ? legacySubscriptionPlan : newSubscriptionPlan;

    return (
      <div>
        <p>
          Become a member to support the community and get full access to all features.{" "}
          <a
            href="https://goodnightjournal.com/privacy#membership"
            target="_blank"
            rel="noopener noreferrer"
            id="billing_learn_more_link"
          >
            Learn more.
          </a>
        </p>
        <div>
          <div className="select-plan" id="select_a_plan">
            <div>
              <input type="radio" name="plan" id="annual" value="annual" checked={userSelectedPlan === "annual"} onChange={this._onPlanChange} /><label htmlFor="annual">Annual <small style={{ color: '#3bc083' }}> - SAVE {isCurrentUserLegacyUser ? 33 : 30}%</small><br /><small>${subscriptionPlan.annual} per year</small></label>
            </div>
            <div>
              <input type="radio" name="plan" id="monthly" value="monthly" checked={userSelectedPlan === "monthly"} onChange={this._onPlanChange} /><label htmlFor="monthly">Monthly<br /><small>${subscriptionPlan.monthly} per month</small></label>
            </div>
          </div>
        </div>
        <div>
          <CouponForm
            applyCouponCode={applyCouponCode}
            couponMsg={couponMsg}
            isCouponLoading={isCouponLoading}
          />
        </div>
        {/*If coupon applied, update the price below to the new price with the coupon */}
        <p>
          <code>
            <small>
              You will be charged for{" "}
              <b>${discountedPrice(userSelectedPlan ==="annual" ? subscriptionPlan.annual : subscriptionPlan.monthly, couponDiscount)}</b> for the {userSelectedPlan ==="annual" ? "annual" : "monthly"} membership.
            </small>
          </code>
        </p>
        <div>
          <CreditCardForm
            ctaText="Become a member"
            creditCardSubmitForm={creditCardSubmit}
            error={error}
            isLoading={isLoading}
          />
        </div>
      </div>
    );
  };

  _renderAnnualMember = () => {
    const {
      stripeCustomer,
      error,
      isLoading,
      creaditCardUpdate,
      showUpdateCreditCard,
      showUpdateCreditCardValue
    } = this.props;

    return (
      <div>
        <p>
          <small>
            <code>
              Next billing date:{" "}
              <b>
                {stripeCustomer.customer &&
                  stripeCustomer.customer.subscriptions.data.length > 0 &&
                  moment
                    .unix(
                      stripeCustomer.customer.subscriptions.data[0]
                        .current_period_end
                    )
                    .format("MMM DD, YYYY")}
              </b><br />$
              {stripeCustomer.customer &&
                stripeCustomer.customer.subscriptions.data.length > 0 &&
                stripeCustomer.customer.subscriptions.data[0].plan.amount
                  .toString()
                  .slice(0, -2) +
                  "." +
                  stripeCustomer.customer.subscriptions.data[0].plan.amount
                    .toString()
                    .slice(-2)}{" "}
              will be charged on your{" "}
              {stripeCustomer.customer &&
                stripeCustomer.customer.subscriptions.data.length > 0 &&
                stripeCustomer.customer.sources.data[0].brand}{" "}
              card ending with{" "}
              {stripeCustomer.customer &&
                stripeCustomer.customer.subscriptions.data.length > 0 &&
                stripeCustomer.customer.sources.data[0].last4}{" "}
              on your next billing date.
            </code>
          </small>
        </p>
        <p>
          <small>
            <b className="link-text">
              <span onClick={showUpdateCreditCard} className="trackit" id="billing_update_credit_card_link">Update credit card</span>
            </b>
          </small>
        </p>
        {showUpdateCreditCardValue && (
          <div>
            <CreditCardForm
              ctaText="Update credit card"
              creditCardSubmitForm={creaditCardUpdate}
              error={error}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
    );
  };

  _renderNonAnnualMember = () => {
    const {
      stripeCustomer,
      error,
      isLoading,
      creaditCardUpdate,
      showUpdateCreditCardValue
    } = this.props;

    return (
      <div>
        <p>
          <small>
            <code>
              Your membership is canceled and will end on{" "}
              {stripeCustomer.customer &&
                moment
                  .unix(
                    stripeCustomer.customer.subscriptions.data[0]
                      .current_period_end
                  )
                  .format("MMM DD, YYYY")}
              . Your membership won't be renewed.
            </code>
          </small>
        </p>
        <p><small><code><Link to="/feedback" id="billing_cancelled_feedback_link">Give us feedback to serve you better!</Link></code></small></p>
        <p>You can reactivate your membership before your current membership expires.</p>
        {error && <p className="error-msg">{error}</p>}
        <button onClick={this._reactivateMembership} disabled={isLoading} id="billing_reactivate_membership_button">
          {isLoading ? (
            <img alt="Loading..." className="loading-icon" src={loadingIcon} />
          ) : (
            "Reactivate my membership"
          )}
        </button>
        {showUpdateCreditCardValue && (
          <div>
            <CreditCardForm
              ctaText="Update credit card"
              creditCardSubmitForm={creaditCardUpdate}
              error={error}
              isLoading={isLoading}
            />
          </div>
        )}
      </div>
    );
  };

  _renderSubscriptionInfo = () => {
    const { stripeCustomer, stripeCustomerId, membership } = this.props;
    if (Object.keys(stripeCustomer).length > 0 && stripeCustomer.customer.deleted) return this._renderFreeMember();
    if (stripeCustomerId && membership && membership !== "expired") {
      if (Object.keys(stripeCustomer).length === 0) return <LoadingCard />;
      if (membership === "annual" || membership === "monthly") return this._renderAnnualMember();
      else return this._renderNonAnnualMember();
    } else {
      return this._renderFreeMember();
    }
  };

  render() {
    const { membership } = this.props;

    return (
      <div>
        <p>
          Current plan:{" "}
          <b>
            {membership && membership !== "expired"
              ? <span style={{textTransform: 'capitalize'}}>{membership} </span>
              : "Free "}
            {membership && membership === "expired" && (
              <span className="badge badge--grey">Membership expired</span>
            )}
            {membership && membership !== "expired" && (
              <span className="badge">
                {this.getMembershipBadge(membership)}
              </span>
            )}
          </b>
        </p>
        {this._renderSubscriptionInfo()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

export default connect(mapStateToProps)(BillingSubscriptionInfo)