import Amplify, { API, graphqlOperation } from 'aws-amplify';
import aws_exports from '../aws-exports';
import * as custom_queries from '../customgraphql/queries';
import * as custom_mutations from '../customgraphql/mutations';

Amplify.configure(aws_exports);

// Stripe create customer and subscribe annual plan
// -----------------------------------------------------------------------------
export function stripeCreateMember(stripeData) {
	return async () => {
		try {
			const data = await API.graphql(
				graphqlOperation(custom_mutations.gnjAmplifyStripeCreateMember, stripeData)
			);
			return JSON.parse(data.data.gnjAmplifyStripeCreateMember);
		} catch (err) {
			console.log("error Stripe user sign up: ", err);
		}
	};
}

// Stripe get customer information
// -----------------------------------------------------------------------------
export function stripeGetCustomerInfo(stripeCustomerId) {
	return async () => {
		try {
			const data = await API.graphql(
				graphqlOperation(custom_queries.gnjAmplifyStripeGetCustomerInfo, { stripeCustomerId: stripeCustomerId })
			);
			return JSON.parse(data.data.gnjAmplifyStripeGetCustomerInfo);
		} catch (err) {
			console.log("error Stripe get customer info: ", err);
		}
	};
}

// Stripe update customer credit card
// -----------------------------------------------------------------------------
export function updateCustomerCreditCard(stripeCustomerId, stripeToken) {
	return async () => {
		try {
			const data = await API.graphql(
				graphqlOperation(custom_mutations.gnjAmplifyupdateCustomerCreditCard, { stripeCustomerId: stripeCustomerId, stripeToken: stripeToken })
			);
			return JSON.parse(data.data.gnjAmplifyupdateCustomerCreditCard);
		} catch (err) {
			console.log("error Stripe update customer credit card: ", err);
		}
	};
}

// Stripe update customer information
// -----------------------------------------------------------------------------
export function updateStripeCustomerInfo(customerData) {
	return async () => {
		try {
			const data = await API.graphql(
				graphqlOperation(custom_mutations.gnjAmplifyStripeUpdateCustomer, customerData)
			);
			return JSON.parse(data.data.gnjAmplifyStripeUpdateCustomer);
		} catch (err) {
			console.log("error Stripe update customer credit card: ", err);
		}
	};
}

// Stripe retrieve coupon code
// -----------------------------------------------------------------------------
export function stripeGetCouponCode(stripeCouponId) {
	return async () => {
		try {
			const data = await API.graphql(
				graphqlOperation(custom_mutations.gnjAmplifyStripeGetCouponCode, { stripeCouponId: stripeCouponId })
			);
			return JSON.parse(data.data.gnjAmplifyStripeGetCouponCode);
		} catch (err) {
			console.log("error Stripe update customer credit card: ", err);
			const errorMsg = (err && err.errors[0] && err.errors[0].message) || 'No coupon code found';
			return {error: errorMsg};
		}
	};
}

// Stripe cancel membership
// -----------------------------------------------------------------------------
export function stripeCancelMembership(stripeSubscriptionId, username, cancel, membershipPlan) {
	return async () => {
		try {
			const data = await API.graphql(
				graphqlOperation(custom_mutations.gnjAmplifyStripeCancelMembership, { 
					stripeSubscriptionId: stripeSubscriptionId, 
					username: username,
					cancel: cancel,
					membershipPlan: membershipPlan
				})
			);
			return JSON.parse(data.data.gnjAmplifyStripeCancelMembership);
		} catch (err) {
			console.log("error Stripe update cancellation status: ", err);
		}
	};
}

// User back up journals
// -----------------------------------------------------------------------------
export function userBackUpJournals(username, deleteBackup) {
	return async () => {
		try {
			const data = await API.graphql(
				graphqlOperation(custom_queries.gnjAmplifyuserBackUpJournals, {username, deleteBackup})
			);
			return JSON.parse(data.data.gnjAmplifyuserBackUpJournals);
		} catch (err) {
			console.log("error userBackUpJournals: ", err);
		}
	};
}