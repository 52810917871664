// Definition of a legacy user: a user who registered before Jan 2nd, 2025 UTC
// As we added new subscription plans, we want to offer a discount to legacy users
// Old plans: $39.99/year or $4.99/month
// New plans: $49.99/year or $5.99/month

export function isLegacyUser(createdAt) {
  if(!createdAt) {
    return false;
  }

  const creationDate = new Date(createdAt);
  const targetDate = new Date("2025-01-02T00:00:00.000Z"); // Jan 2nd, 2025 UTC

  return creationDate < targetDate;
}