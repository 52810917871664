/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      uuid
      displayName
      profileImage
      membership
      totalJournal
      followingCount
      followerCount
      bookOrder
      createdAt
      updatedAt
      lovedJournals {
        nextToken
        __typename
      }
      journals {
        nextToken
        __typename
      }
      books {
        nextToken
        __typename
      }
      publicJournals {
        nextToken
        __typename
      }
      lovedComments {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      chatRoomUser {
        nextToken
        __typename
      }
      chatMassages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      uuid
      displayName
      profileImage
      membership
      totalJournal
      followingCount
      followerCount
      bookOrder
      createdAt
      updatedAt
      lovedJournals {
        nextToken
        __typename
      }
      journals {
        nextToken
        __typename
      }
      books {
        nextToken
        __typename
      }
      publicJournals {
        nextToken
        __typename
      }
      lovedComments {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      chatRoomUser {
        nextToken
        __typename
      }
      chatMassages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      uuid
      displayName
      profileImage
      membership
      totalJournal
      followingCount
      followerCount
      bookOrder
      createdAt
      updatedAt
      lovedJournals {
        nextToken
        __typename
      }
      journals {
        nextToken
        __typename
      }
      books {
        nextToken
        __typename
      }
      publicJournals {
        nextToken
        __typename
      }
      lovedComments {
        nextToken
        __typename
      }
      comments {
        nextToken
        __typename
      }
      notifications {
        nextToken
        __typename
      }
      chatRoomUser {
        nextToken
        __typename
      }
      chatMassages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateFollowing = /* GraphQL */ `
  subscription OnCreateFollowing {
    onCreateFollowing {
      id
      user
      following
      followingProfile
      followerProfile
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFollowing = /* GraphQL */ `
  subscription OnUpdateFollowing {
    onUpdateFollowing {
      id
      user
      following
      followingProfile
      followerProfile
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFollowing = /* GraphQL */ `
  subscription OnDeleteFollowing {
    onDeleteFollowing {
      id
      user
      following
      followingProfile
      followerProfile
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserLovedJournal = /* GraphQL */ `
  subscription OnCreateUserLovedJournal {
    onCreateUserLovedJournal {
      id
      userLovedJournalUserId
      userLovedJournalJournalId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateUserLovedJournal = /* GraphQL */ `
  subscription OnUpdateUserLovedJournal {
    onUpdateUserLovedJournal {
      id
      userLovedJournalUserId
      userLovedJournalJournalId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteUserLovedJournal = /* GraphQL */ `
  subscription OnDeleteUserLovedJournal {
    onDeleteUserLovedJournal {
      id
      userLovedJournalUserId
      userLovedJournalJournalId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateJournal = /* GraphQL */ `
  subscription OnCreateJournal($owner: String) {
    onCreateJournal(owner: $owner) {
      id
      privacy
      draft
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      journalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      owner
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateJournal = /* GraphQL */ `
  subscription OnUpdateJournal($owner: String) {
    onUpdateJournal(owner: $owner) {
      id
      privacy
      draft
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      journalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      owner
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteJournal = /* GraphQL */ `
  subscription OnDeleteJournal($owner: String) {
    onDeleteJournal(owner: $owner) {
      id
      privacy
      draft
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      journalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      owner
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateBook = /* GraphQL */ `
  subscription OnCreateBook {
    onCreateBook {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      journals {
        nextToken
        __typename
      }
      publicJournals {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateBook = /* GraphQL */ `
  subscription OnUpdateBook {
    onUpdateBook {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      journals {
        nextToken
        __typename
      }
      publicJournals {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteBook = /* GraphQL */ `
  subscription OnDeleteBook {
    onDeleteBook {
      id
      book
      bookUserId
      bookCoverImg
      bookJournalCount
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      journals {
        nextToken
        __typename
      }
      publicJournals {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreatePublicJournal = /* GraphQL */ `
  subscription OnCreatePublicJournal {
    onCreatePublicJournal {
      id
      privacy
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      publicJournalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      lovedUsers {
        nextToken
        __typename
      }
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        createdAt
        updatedAt
        __typename
      }
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdatePublicJournal = /* GraphQL */ `
  subscription OnUpdatePublicJournal {
    onUpdatePublicJournal {
      id
      privacy
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      publicJournalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      lovedUsers {
        nextToken
        __typename
      }
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        createdAt
        updatedAt
        __typename
      }
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeletePublicJournal = /* GraphQL */ `
  subscription OnDeletePublicJournal {
    onDeletePublicJournal {
      id
      privacy
      content
      journalAuthorId
      markedAs
      lovedCount
      commentCount
      journalWordCount
      publicJournalBookId
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      lovedUsers {
        nextToken
        __typename
      }
      book {
        id
        book
        bookUserId
        bookCoverImg
        bookJournalCount
        createdAt
        updatedAt
        __typename
      }
      comments {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateChallenge = /* GraphQL */ `
  subscription OnCreateChallenge {
    onCreateChallenge {
      id
      yearMonth
      user
      userDisplayName
      userProfileImage
      journalsPerMonth
      journalWordCountPerMonth
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateChallenge = /* GraphQL */ `
  subscription OnUpdateChallenge {
    onUpdateChallenge {
      id
      yearMonth
      user
      userDisplayName
      userProfileImage
      journalsPerMonth
      journalWordCountPerMonth
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteChallenge = /* GraphQL */ `
  subscription OnDeleteChallenge {
    onDeleteChallenge {
      id
      yearMonth
      user
      userDisplayName
      userProfileImage
      journalsPerMonth
      journalWordCountPerMonth
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUserLovedComment = /* GraphQL */ `
  subscription OnCreateUserLovedComment {
    onCreateUserLovedComment {
      id
      userLovedCommentUserId
      userLovedCommentCommentId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      comment {
        id
        content
        commentLovedCount
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateUserLovedComment = /* GraphQL */ `
  subscription OnUpdateUserLovedComment {
    onUpdateUserLovedComment {
      id
      userLovedCommentUserId
      userLovedCommentCommentId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      comment {
        id
        content
        commentLovedCount
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteUserLovedComment = /* GraphQL */ `
  subscription OnDeleteUserLovedComment {
    onDeleteUserLovedComment {
      id
      userLovedCommentUserId
      userLovedCommentCommentId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      comment {
        id
        content
        commentLovedCount
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      content
      commentLovedCount
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        __typename
      }
      lovedUsers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      content
      commentLovedCount
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        __typename
      }
      lovedUsers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      content
      commentLovedCount
      createdAt
      updatedAt
      author {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      journal {
        id
        privacy
        content
        journalAuthorId
        markedAs
        lovedCount
        commentCount
        journalWordCount
        publicJournalBookId
        createdAt
        updatedAt
        __typename
      }
      lovedUsers {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      content
      category
      link
      read
      actionUser {
        userID
        displayName
        photoURL
        __typename
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      content
      category
      link
      read
      actionUser {
        userID
        displayName
        photoURL
        __typename
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      content
      category
      link
      read
      actionUser {
        userID
        displayName
        photoURL
        __typename
      }
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateChatRoomUser = /* GraphQL */ `
  subscription OnCreateChatRoomUser {
    onCreateChatRoomUser {
      id
      chatRoomUserUserId
      chatRoomUserChatRoomId
      members
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      chatRoom {
        id
        createdBy
        receiverHasRead
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateChatRoomUser = /* GraphQL */ `
  subscription OnUpdateChatRoomUser {
    onUpdateChatRoomUser {
      id
      chatRoomUserUserId
      chatRoomUserChatRoomId
      members
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      chatRoom {
        id
        createdBy
        receiverHasRead
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteChatRoomUser = /* GraphQL */ `
  subscription OnDeleteChatRoomUser {
    onDeleteChatRoomUser {
      id
      chatRoomUserUserId
      chatRoomUserChatRoomId
      members
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      chatRoom {
        id
        createdBy
        receiverHasRead
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateChatRoom = /* GraphQL */ `
  subscription OnCreateChatRoom {
    onCreateChatRoom {
      id
      createdBy
      receiverHasRead
      createdAt
      updatedAt
      chatRoomUsers {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateChatRoom = /* GraphQL */ `
  subscription OnUpdateChatRoom {
    onUpdateChatRoom {
      id
      createdBy
      receiverHasRead
      createdAt
      updatedAt
      chatRoomUsers {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteChatRoom = /* GraphQL */ `
  subscription OnDeleteChatRoom {
    onDeleteChatRoom {
      id
      createdBy
      receiverHasRead
      createdAt
      updatedAt
      chatRoomUsers {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      content
      messageChatRoomId
      messageUserId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      chatRoom {
        id
        createdBy
        receiverHasRead
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      content
      messageChatRoomId
      messageUserId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      chatRoom {
        id
        createdBy
        receiverHasRead
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      content
      messageChatRoomId
      messageUserId
      createdAt
      updatedAt
      user {
        id
        uuid
        displayName
        profileImage
        membership
        totalJournal
        followingCount
        followerCount
        bookOrder
        createdAt
        updatedAt
        __typename
      }
      chatRoom {
        id
        createdBy
        receiverHasRead
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
